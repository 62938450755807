import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
// import Skeleton from 'react-loading-skeleton'
import { Title } from 'utils/Title'
import PageMainHeader from 'components/PageMainHeader'
import { AuthContext } from "contexts/AuthContext"
import { SettingsContext } from 'contexts/SettingsContext'
import PageMenuHeader from 'components/PageMenuHeader'
import _ from "lodash";
import Banner from '@leafygreen-ui/banner'
import Stepper from "@leafygreen-ui/stepper";
import Button from "@leafygreen-ui/button";
import Icon from "@leafygreen-ui/icon";
import ConfirmationModal from "@leafygreen-ui/confirmation-modal";
import { errorHandler } from 'utils/errorHandler'
import { GridLoader } from 'react-spinners'
import { useHistory } from 'react-router-dom'
import saveLog from 'utils/log'
import socketIOClinet from "socket.io-client";

const title="Sync Orders"
const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

let initialFnResult = {result: false, message: null};

const initialSteps = [
  {name: 'Fetch ShipStation orders', id: 1},
  {name: 'Import orders into Printflo', id: 2},
  {name: 'Create items', id: 3},
  {name: 'Create packing items', id: 4},
  {name: 'Assign Bins', id: 5},
  {name: 'Update order quantities', id: 6},
]

export default function SyncOrdersBackend() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [currentStep, setCurrentStep] = React.useState(0)
  // const {isLoading, isError, error} = props
  const [msg, setMsg] = React.useState('Click to run sync orders')
  const [disableSyncBtn, setDisableSyncBtn] = React.useState(false);
  const [openConfirmSyncBanner, setOpenConfirmSyncBanner] = React.useState(false);
  const bannerVariantRef = React.useRef('info');
  const [steps, setSteps] = React.useState(initialSteps);
  const history = useHistory()

  React.useEffect(() => {
    if(settings) {
      if(settings?.binAssignmentType === 'hanger') {
        setSteps(() => {
          // let tempStep = _.filter(initialSteps, (s) => !_.includes(s.name, 'Bin'))
          // tempStep[1].name = tempStep[1].name + ' & Assign Bins';
          return [
            {name: 'Fetch ShipStation orders', id: 1},
            {name: 'Import orders into Printflo & Assign Bins', id: 2},
            {name: 'Create items', id: 3},
            {name: 'Create packing items', id: 4},
            {name: 'Update order quantities', id: 5},
          ]
        })
      }
    }
      
    return () => {
      setSteps(initialSteps);
      if(window?.printflo_api) {
        window.printflo_api._syncOrdersStoreClear();
      } else {
        socket.emit("on-sync-order:storeClear")
      }
    }
  }, [settings])

  const syncOrders = async () => {
    console.log("* syncOrders init");
    console.time('Sync Orders Render Time');

    await saveLog(`sync orders init at ${new Date()}`);

    if(currentStep !== 0) setCurrentStep(0);
    
    setOpenConfirmSyncBanner(false);
    // Each fn's initial result
    let fetchShipStationOrdersReult = initialFnResult;
    let inActiveLocalOrdersResult = initialFnResult;
    let createLocalOrdersResult = initialFnResult;
    let createLocalItemResult = initialFnResult;
    let inActivatePackingItemsResult = initialFnResult;
    let createPackingItemsResult = initialFnResult;
    let assignBinsResult = initialFnResult;
    let updateOrderQtyResult = initialFnResult;

    // 1. get all shipstation orders
    try {
      console.time('[Step1 Render Time]')
      fetchShipStationOrdersReult = await fetchShipStationOrders()
      console.log('[syncOrders] fetchShipStationOrdersReult: ', fetchShipStationOrdersReult)
      setMsg(fetchShipStationOrdersReult.message)
      console.timeEnd('[Step1 Render Time]')
    } catch (error) {
      console.error('[syncOrders] fetchShipStationOrders error: ', error)
      bannerVariantRef.current = 'danger'
      setMsg(error);
      return;
    }

    // 2. import local order into Printflo
    // 2.1 in-activate local orders
    try {
      setCurrentStep(1)
      inActiveLocalOrdersResult = await inActiveLocalOrders()
      console.log("- inActiveLocalOrdersResult: ", inActiveLocalOrdersResult)
      setMsg(inActiveLocalOrdersResult.message)
    } catch (error) {
      console.log('- inActiveLocalOrders error: ', error)
      const retVal = errorHandler(error)
      bannerVariantRef.current ='danger';
      setMsg(retVal)
      return
    }

    // 2.2 create orders into Printflo if a ShipStation order not exists
    try {
      createLocalOrdersResult = await createLocalOrders();
      console.log("- createLocalOrdersResult: ", createLocalOrdersResult)
      setMsg(createLocalOrdersResult.message)
    } catch (error) {
      console.error('-[createLocalOrdersResult] error: ', error)
      const retVal = errorHandler(error)
      bannerVariantRef.current ='danger';
      setMsg(retVal)
      return
    }

    // 3 create items
    try {
      setCurrentStep(2);
      createLocalItemResult = await createItems()
      console.log('- createLocalItemResult: ', createLocalItemResult)
      setMsg(createLocalItemResult.message)

    } catch (error) {
      console.error(error);
      setMsg(error)

      return
    }
    

    // 4. create packing items
    try {
      setCurrentStep(3);
      bannerVariantRef.current ='info';
      setMsg('Creating packing items init')
      inActivatePackingItemsResult = await inActivatePackingItems()
      console.log('- inActivatePackingItemsResult: ', inActivatePackingItemsResult)
      createPackingItemsResult = await createPackingItems()
      console.log('- createPackingItemsResult: ', createPackingItemsResult)
      setMsg(createLocalItemResult.message)
    } catch (error) {
      console.error(error);
      bannerVariantRef.current ='danger';
      setMsg(error)
      return
    }

    // 5. assign bins
    if(settings?.binAssignmentType !=='hanger') {
      try {
        setCurrentStep(4);
        assignBinsResult = await assignBins()
        console.log('- assignBinsResult: ', assignBinsResult)
        setMsg(assignBinsResult.message)
      } catch (error) {
        console.error(error);
        bannerVariantRef.current ='danger';
        setMsg(error)
        return
      }

    }

    // 6. update order qty
    try {
      if(settings?.binAssignmentType !=='hanger') {
        setCurrentStep(5);
      } else {
        setCurrentStep(4);
      }
      updateOrderQtyResult = await updateOrderQty()
      console.log('- updateOrderQtyResult: ', updateOrderQtyResult)
      setMsg(updateOrderQtyResult.message);

      if(updateOrderQtyResult.result) {
        setCurrentStep(6);
        setMsg('Done syncing orders');

        toast.info('Click to move work orders', {
          autoClose: false,
          position: 'bottom-right',
          onClose: () => history.push('/work-orders')
        })
      }
      
    } catch (error) {
      console.error(error);
      bannerVariantRef.current ='danger';
      setMsg(error)
      setDisableSyncBtn(false)

      return
    }
    console.timeEnd('Sync Orders Render Time')
    
  };

  function fetchShipStationOrders() {
    return new Promise(async (resolve, reject) => {
      try {
        setDisableSyncBtn(true)
        bannerVariantRef.current ='info';
        setMsg('fetchShipStationOrders init')
        let fnResult = initialFnResult;
        const orderStatus = settings?.orderStatus || "awaiting_shipment";
        const useAwaitingPayment = settings?.useAwaitingPayment || false;
        if(window?.printflo_api) {
          fnResult = await window.printflo_api._syncOrdersFetchOrders({orderStatus, useAwaitingPayment});
          if(_.isError(fnResult)) throw fnResult;
        } else {
          if (socket.connected) {
            const onSyncOrderFetchPromise = () => {
              return new Promise((resolve) => {
                socket.emit("on-sync-order:fetch", {orderStatus, useAwaitingPayment}, (callback) => {
                  console.log("[on-sync-order:fetch] callback: ", callback)
                  resolve(callback)
                });
              })
            } 
            
            fnResult = await onSyncOrderFetchPromise();
            console.log("[onSyncOrderFetchPromise] fnResult: ", fnResult);
            if(_.isError(fnResult)) throw fnResult;
          } else {
            alert(
              "Sync orders can only be executed on stations that have a Printflo license assigned"
            );
          }
        }
        // fnResult={result: true, message: "Completed fetching ShipStation orders"}
        setDisableSyncBtn(false)
        resolve(fnResult)
      } catch (error) {
        console.error('-[syncOrders] ssOrder error: ', error)
        const retVal = errorHandler(error);
        setDisableSyncBtn(false)
        reject(retVal)
      }
    })
  }

  function inActiveLocalOrders() {
    console.log("* In-activating local orders init")
    return new Promise(async (resolve, reject) => {
      setDisableSyncBtn(true);
      bannerVariantRef.current ='info';
      setMsg('In-activating local orders init')
      try {
        let orderStatusFilter = {
          orderStatus: { $in: ["awaiting_shipment"] },
          source: 'shipstation'
        };
    
        if (settings?.useAwaitingPayment) {
          orderStatusFilter = {
            ...orderStatusFilter,
            orderStatus: { $in: ["awaiting_shipment", "awaiting_payment"] },
          };
        }
        let fnResult
        if(window?.printflo_api) {
          fnResult = await window.printflo_api._syncOrdersInActivateLocalOrders(orderStatusFilter);
          if(_.isError(fnResult)) throw fnResult;
        } else {
          if (socket.connected) {
            const onSyncOrderInActivateLocalOrdersPromise = () => {
              return new Promise((resolve) => {
                socket.emit("on-sync-order:inActivateLocalOrders", orderStatusFilter, (callback) => {
                  console.log("[on-sync-order:inActivateLocalOrders] callback: ", callback)
                  resolve(callback)
                });
              })
            } 
            
            fnResult = await onSyncOrderInActivateLocalOrdersPromise();
            console.log("[onSyncOrderInActivateLocalOrdersPromise] fnResult: ", fnResult);
            if(_.isError(fnResult)) throw fnResult;
          } else {
            alert(
              "Sync orders can only be executed on stations that have a Printflo license assigned"
            );
          }
        }
        // console.log('[inActiveLocalOrders] fnResult: ', fnResult);
        setDisableSyncBtn(false)
        resolve(fnResult)
      } catch (error) {
        setDisableSyncBtn(false)
        reject(errorHandler(error))
      }
      console.timeEnd('[inActiveLocalOrders] render time');

    })
    
  }

  function createLocalOrders() {
    console.log('* createLocalOrders init')

    return new Promise(async (resolve, reject) => {
      setDisableSyncBtn(true)
      bannerVariantRef.current ='info';
      setMsg('Importing orders init')
      let fnResult = initialFnResult;
      try {
        const binAssignmentType = settings?.binAssignmentType
        if(window?.printflo_api) {
          fnResult = await window.printflo_api._syncOrdersCreateLocalOrders({binAssignmentType});
          if(_.isError(fnResult)) throw fnResult;
        } else {
          if (socket.connected) {
            const onSyncOrderCreateLocalOrdersPromise = () => {
              return new Promise((resolve) => {
                socket.emit("on-sync-order:createLocalOrders", {binAssignmentType}, (callback) => {
                  console.log("[on-sync-order:createLocalOrders] callback: ", callback)
                  resolve(callback)
                });
              })
            } 
            
            fnResult = await onSyncOrderCreateLocalOrdersPromise();
            console.log("[onSyncOrderCreateLocalOrdersPromise] fnResult: ", fnResult);
            if(_.isError(fnResult)) throw fnResult;
          } else {
            alert(
              "Sync orders can only be executed on stations that have a Printflo license assigned"
            );
          }
        }

        bannerVariantRef.current = 'info'
        setDisableSyncBtn(false)
        resolve(fnResult);
      } catch (error) {
        console.error("[createLocalOrders] error: ", error);
        setDisableSyncBtn(false)
        reject(errorHandler(error));
      }
    })
  }

  function createItems () {
    console.log(`* createItems init`);
    return new Promise(async (resolve, reject) => {
      try {
        setDisableSyncBtn(true);
        bannerVariantRef.current ='info';
        setMsg('Creating items init')
       
        let useProductDescription = false, usePrimarySKU = false, primarySKUSeparator="-";
        if(settings) {
          // console.info('[createItems] settings: ', settings)
          useProductDescription = settings?.useProductDescription;
          usePrimarySKU = settings?.usePrimarySKU;
          if(!_.isNil(settings?.primarySKUSeparator)) {
            primarySKUSeparator = settings.primarySKUSeparator;
          } 
        }
        console.log(`[createItems] useProductDescription: ${useProductDescription}`)
        console.log(`[createItems] usePrimarySKU: ${usePrimarySKU}`)
        console.log(`[createItems] primarySKUSeparator: ${primarySKUSeparator}`);

        let fnResult;
        if(window?.printflo_api) {
          fnResult = await window.printflo_api._syncOrdersCreateItems({useProductDescription,usePrimarySKU,primarySKUSeparator})
          if(_.isError(fnResult)) throw fnResult;
        } else {
          if (socket.connected) {
            const onSyncOrderCreateItemsPromise = () => {
              return new Promise((resolve) => {
                socket.emit("on-sync-order:createItems", {useProductDescription,usePrimarySKU,primarySKUSeparator}, (callback) => {
                  console.log("[on-sync-order:createItems] callback: ", callback)
                  resolve(callback)
                });
              })
            } 
            
            fnResult = await onSyncOrderCreateItemsPromise();
            console.log("[onSyncOrderCreateItemsPromise] fnResult: ", fnResult);
            if(_.isError(fnResult)) throw fnResult;
          } else {
            alert(
              "Sync orders can only be executed on stations that have a Printflo license assigned"
            );
          }
        }
        setDisableSyncBtn(false);
        resolve(fnResult);
      } catch (error) {
        console.info("[createItem] error", { message: error });
        setDisableSyncBtn(false);
        reject(errorHandler(error));
      }
    });
  };

  function inActivatePackingItems() {
    console.log(`* inActivatePackingItems init`);
    return new Promise(async (resolve, reject) => {
      // delete a packingItem that not exists in orderItems by orderId;
      try {
        setDisableSyncBtn(true);
        bannerVariantRef.current = 'info';
        setMsg('In-activating packing items init')
        let fnResult = initialFnResult;
        if(window?.printflo_api) {
          fnResult = await window.printflo_api._syncOrdersInActivatePackingItems()
          if(_.isError(fnResult)) throw fnResult;
        } else {
          if (socket.connected) {
            const onSyncOrderInActivatePackingItemsPromise = () => {
              return new Promise((resolve) => {
                socket.emit("on-sync-order:inActivatePackingItems", (callback) => {
                  console.log("[on-sync-order:inActivatePackingItems] callback: ", callback)
                  resolve(callback)
                });
              })
            } 
            
            fnResult = await onSyncOrderInActivatePackingItemsPromise();
            console.log("[onSyncOrderInActivatePackingItemsPromise] fnResult: ", fnResult);
            if(_.isError(fnResult)) throw fnResult;
          } else {
            alert(
              "Sync orders can only be executed on stations that have a Printflo license assigned"
            );
          }
        }
        setDisableSyncBtn(false);
        resolve(fnResult);
      } catch (error) {
        console.error(`[inActivatePackingItems] error`, error);
        const retVal = errorHandler(error);
        setDisableSyncBtn(false);
        reject(retVal);
      }
    });
  }

  function createPackingItems() {
    console.log("* createPackingItems init");
    return new Promise(async (resolve, reject) => {
      setDisableSyncBtn(true);
      bannerVariantRef.current = 'info'
      setMsg("Creating packing items init")
      
      let useItemOptions = false;
      let itemOptionKeys;
      let usePrimarySKU = false;
      let primarySKUSeparator = "-";

      if(settings) {
        // console.info('-[createPackingItems] settings: ', settings)
        useItemOptions = settings?.useItemOptions;
        itemOptionKeys = settings?.itemOptionKeys;
        usePrimarySKU = settings?.usePrimarySKU;
        if(!_.isNil(settings?.primarySKUSeparator)) {
          primarySKUSeparator = settings.primarySKUSeparator;
        }
      }
      console.info(`[createPackingItems]: useItemOptions `, useItemOptions)
      console.info(`[createPackingItems]: itemOptionKeys:settings `, itemOptionKeys)
      console.info(`[createPackingItems]: usePrimarySKU: ${usePrimarySKU}, primarySKUSeparator: ${primarySKUSeparator}`);
      let fnResult;
      
      const payload = {
        useItemOptions,
        itemOptionKeys,
        usePrimarySKU,
        primarySKUSeparator,
      }

      try {
        if(window?.printflo_api) {
          fnResult = await window.printflo_api._syncOrdersCreatePackingItems(payload)
          if(_.isError(fnResult)) throw fnResult;
        } else {
          if (socket.connected) {
            const onSyncOrderCreatePackingItemsPromise = () => {
              return new Promise((resolve) => {
                socket.emit("on-sync-order:createPackingItems", payload, (callback) => {
                  console.log("[on-sync-order:createPackingItems] callback: ", callback)
                  resolve(callback)
                });
              })
            } 
            
            fnResult = await onSyncOrderCreatePackingItemsPromise(payload);
            console.log("[onSyncOrderCreatePackingItemsPromise] fnResult: ", fnResult);
            if(_.isError(fnResult)) throw fnResult;
          } else {
            alert(
              "Sync orders can only be executed on stations that have a Printflo license assigned"
            );
          }
        }
        setDisableSyncBtn(false)
        resolve(fnResult)
      } catch (error) {
        console.error(`[createPackingItems] error`, error);
        const retVal = errorHandler(error);
        setDisableSyncBtn(false);
        reject(retVal);
      }
    });
  }

  function assignBins() {
    console.log("* assignBins init");
    return new Promise(async (resolve, reject) => {
      try {
        setDisableSyncBtn(true);
        bannerVariantRef.current = 'info'
        setMsg("Assign Bins init")

        let fnResult = initialFnResult;
        let binAssignmentType = "order";
        if(settings) {
          // console.info('[assignBins] settings: ', settings)
          binAssignmentType = settings?.binAssignmentType;
        }
        console.log("[assignBins] binAssignmentType", binAssignmentType);
        if(window?.printflo_api) {
          fnResult = await window.printflo_api._syncOrdersAssignBins({binAssignmentType})
          if(_.isError(fnResult)) throw fnResult;
        } else {
          if (socket.connected) {
            const onSyncOrderAssignBinsPromise = () => {
              return new Promise((resolve) => {
                socket.emit("on-sync-order:assignBins", {binAssignmentType}, (callback) => {
                  console.log("[on-sync-order:assignBins] callback: ", callback)
                  resolve(callback)
                });
              })
            } 
            
            fnResult = await onSyncOrderAssignBinsPromise();
            console.log("[onSyncOrderAssignBinsPromise] fnResult: ", fnResult);
            if(_.isError(fnResult)) throw fnResult;
          } else {
            alert(
              "Sync orders can only be executed on stations that have a Printflo license assigned"
            );
          }
        }
        setDisableSyncBtn(false);
        resolve(fnResult);
      } catch (error) {
        console.error("[assignBins] assign bin error", error);
        setDisableSyncBtn(false);
        reject(errorHandler(error));
      }
    });
  }

  function updateOrderQty() {
    return new Promise(async (resolve, reject) => {
      console.log("* updateOrderQty init");
      // console.info('[updateOrderQty] packingItems: ', packingItems)
      try {
        setDisableSyncBtn(true);
        bannerVariantRef.current ='info';
        setMsg('Updating order quantities init')
        let fnResult
        if(window?.printflo_api) {
          fnResult = await window.printflo_api._syncOrdersUpdateOrderQty();
          if(_.isError(fnResult)) throw fnResult;
        } else {
          if (socket.connected) {
            const onSyncUpdateOrderQtyPromise = () => {
              return new Promise((resolve) => {
                socket.emit("on-sync-order:updateOrderQty", (callback) => {
                  console.log("[on-sync-order:updateOrderQty] callback: ", callback)
                  resolve(callback)
                });
              })
            } 
            
            fnResult = await onSyncUpdateOrderQtyPromise();
            console.log("[onSyncOrderAssignBinsPromise] fnResult: ", fnResult);
            if(_.isError(fnResult)) throw fnResult;
          } else {
            alert(
              "Sync orders can only be executed on stations that have a Printflo license assigned"
            );
          }
        }
        setDisableSyncBtn(false);
        resolve(fnResult);
      } catch (error) {
        console.error("[updateOrderQty] error: ", error);
        setDisableSyncBtn(false);
        reject(errorHandler(error));
      }
    });
  };

  function stepHandler(id) {
    console.log("* stepHandler init")
    console.log('[stepHandler] step: ', id, typeof id)
    bannerVariantRef.current = 'info';
    switch(id) {
      case 1:
        fetchShipStationOrders()
        .then(result => {
          console.log("[stepHandler:fetchShipStationOrders] result: ", result)
          setMsg(result.message)
          if(result.result) setCurrentStep(1)
        })
        .catch(error => {
          console.log("[stepHandler:fetchShipStationOrders] error: ", error)
          setMsg(error?.message)
        })
        break;
      case 2:
        inActiveLocalOrders()
          .then(result => {
            setMsg(result.message)
            return createLocalOrders()
          })
          .then(result => {
            setMsg(result.message)
            if(result.result) setCurrentStep(2)
          })
        break;
      case 3:
        createItems().then(result => {
          setMsg(result.message)
          if(result.result) setCurrentStep(3)
        })
        break;
      case 4:
        inActivatePackingItems()
          .then(result => {
            setMsg(result.message)
            return createPackingItems()
          })
          .then(result => {
            setMsg(result.message)
            setCurrentStep(4)
          })
        break;
      case 5:
        assignBins().then(result => {
          setMsg(result.message)
          setCurrentStep(5)
        })

        break;     
    
      case 6:
        updateOrderQty().then(result => {
          setMsg(result.message)
          if(result.result) {
            setCurrentStep(6);
            setMsg('Done syncing orders')
            toast.info('Click to move work orders', {
              autoClose: false,
              position: 'bottom-right',
              onClose: () => history.push('/work-orders')
            })
          }
        })
        
        break;  
      default:
        break;
    }
  }

  return <>
      <Title title={title} />
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <ToastContainer theme='dark'/>
      <section className="primary section-sync-orders">
        <PageMenuHeader>
          <>
            <Button
              variant="primary"
              onClick={() => setOpenConfirmSyncBanner(true)}
              leftGlyph={<Icon glyph="Download" />}
              disabled={disableSyncBtn}
              className="swing-icon"
              > Sync Orders
            </Button>
          </>
          
        </PageMenuHeader>
        <div className="card p-10 mb-10">
          <Banner variant={bannerVariantRef.current}>
            {msg} <GridLoader color={"#09804C"} size={5} width={20} loading={disableSyncBtn}/>
          </Banner>
        </div>
        <div className="card p-10">
          <Stepper currentStep={currentStep} maxDisplayedSteps={6} completedStepsShown={6}>
            {steps.map((s, index) => {
              return <span key={s.id} onClick={() => stepHandler(s.id)} className="step-handler">{index+1}. {s.name}</span>
            })}
          </Stepper>
        </div>
        
        {/* <div className='card p-10 mt-10 console-card'>
          <b>Console</b>
          <div className="console-wrapper" ref={consoleRef}>
            <Console logs={logs} variant="dark" filter={['log', 'error']} />
          </div>
         }</div>
        */}

        <ConfirmationModal
          open={openConfirmSyncBanner}
          onConfirm={() => syncOrders()}
          onCancel={() => setOpenConfirmSyncBanner(false)}
          title="Confirm sync orders"
          buttonText="Confirm"
        >
          The sync orders may take some minutes to complete depending on the
          amount of data. A done message will appear upon completion.
        </ConfirmationModal>
      </section>
    </>
}