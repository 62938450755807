import React, { useState, useEffect, useContext, useRef } from "react";
// import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import { SettingsContext } from "contexts/SettingsContext";
import { AuthContext } from "contexts/AuthContext";

import WorkOrderDetailsHeader from "./WorkOrderDetailsHeader";
import WorkOrderDetailsItems from "./WorkOrderDetailsItems";
import { Title } from "utils/Title";
import { submitItem } from "utils/items";
import {
  updatePackingItem,
  addPackingItem,
  deletePackingItem,
  fetchPackingItems,
  updateManyPackingItem,
} from "utils/packingItems";
import {
  createShippingLabel,
  fetchListShipments,
  getShipStationOrder,
  getWarehouse,
} from "utils/shipstation";
import { deleteLocalOrder, updateOrder, getOrderId, fetchWorkOrder } from "utils/orders";
import { assignBin, updateBin } from "utils/bins";
import { errorHandler } from "utils/errorHandler";
import { ModalPreloader } from "utils/Preloader";
import { useMutation, useQueryClient } from "react-query";
// import { findOnePickFinishedProduct } from "utils/pickFinishedProduct";
import { generatePMTHandler } from "utils/pretreatment";
import {
  FaAddressBook,
  FaCreativeCommonsSampling,
  FaPrint,
  FaRemoveFormat,
  FaShip,
} from "react-icons/fa";
import OrderItemModal from "components/modals/OrderItemModal";
import ModalItem from "components/modals/ModalProduction";
import PageMainHeader from "components/PageMainHeader";
import PageMenuHeader from "components/PageMenuHeader";
import Button from "@leafygreen-ui/button";
import Banner from "@leafygreen-ui/banner";
import IconButton from "@leafygreen-ui/icon-button";
import { useDebouncedCallback } from "use-debounce/lib";
import Search from "components/Search";
// import { searchByOrderNumber } from "utils/search";
import { PackingSlipTemplate } from "utils/PackingSlipTemplate";
import { printOrderItemLabel } from "utils/printOrderItemLabel";
import Skeleton from "react-loading-skeleton";
import queryString from "query-string";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GridLoader } from 'react-spinners'
import { Option, Select } from "@leafygreen-ui/select";
import { sessionValidate } from "utils/sessionValidate";
import { barcodeScannedHandler } from "utils/barcodeScannedHandler";
import socketIOClinet from "socket.io-client";

// import queryString from "query-string";

const title = "Order Details";
const socket = socketIOClinet(process.env.REACT_APP_SOCKET_ENDPOINT);

const initialOrderItem = {
  sku: "",
  quantity: "",
  picked: "",
  checkedOutQty: "",
  isActive: true,
};

const WorkOrderDetails = (props) => {
  // console.log("WorkOrderDetails props", props);
  const [shipstationOrder, setShipstationOrder] = useState(null);
  const [shipProcessResult, setShipProcessResult] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const { platens, settings, hostname, sessions } = useContext(SettingsContext);
  const [orderItemMode, setOrderItemMode] = useState("edit");
  const [orderItemModalIsOpen, setOrderItemModalIsOpen] = useState(false);
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [shipFrom, setShipFrom] = useState(null);
  const [modalPreloaderIsOpen, setModalPreloaderIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const [localOrder, setLocalOrder] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const sourceOrderRef = useRef(null);
  const queryClient = useQueryClient();
  const bannerVariantRef = useRef('info');
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const isEditItemSubmitRef = useRef(false)
  const orderIdRef = useRef(null);
  const onQueueCadlinkCount = useRef(0);
  const onQueueCadlinkResultCount = useRef(0);
  const [disableBatchQueueBtn, setDisableBatchQueueBtn] = useState(false);
  const [selectedSearchOption, setSelectedSearchOption] = useState("orderNumber");
  const [sessionMsg, setSessionMsg] = useState(null)
  const isSessionValidRef = useRef(true);
  const isLoadingBatchQueueRef = useRef(false);
  const componentValueRef = useRef("");
  // console.log("user", user);
  // console.log("- orderIdRef", orderIdRef);
  // console.log("testLabel", settings?.testLabel);
  // console.log("defaultIntegratedAutomation", settings?.defaultIntegratedAutomation);
  // console.log("- useWorkOrderStatus: ", settings?.useWorkOrderStatus);
  // console.log("- settings: ", settings);

  const fetchShipStationOrder = useMutation(
    async ({ orderId }) => {
      console.log("* WorkOrderDetails:fetchShipStationOrder init");
      return await getShipStationOrder(orderId);
    },
    {
      onMutate: () => {
        setIsLoading(true);
      },
      onError: (err) => {
        console.log("[WorkOrderDetails:fetchShipStationOrder] error", err);
        console.log("[WorkOrderDetails:fetchShipStationOrder] error:message: ", err?.message);
        let m = "Error occurred while fetcing ShipStation order";
        if(err?.response?.data) {
          if(err?.response?.data?.message) m = err?.response?.data?.message;
        } else if(err?.message) {
          m = err?.message
        }
        setMsg(m);
        setIsLoading(false);
      },
      onSuccess: (ssOrder, variables) => {
        // console.log("[fetchShipStationOrder:onSuccess] ssOrder: ", ssOrder);
        if (ssOrder?.orderStatus === "shipped") {
          fetchShipment.mutate({ orderId: variables.orderId });
        }
        setShipstationOrder(ssOrder);
        setIsLoading(false);
      },
    }
  );

  const fetchShipment = useMutation(
    async ({ orderId }) => {
      console.log("* fetchShipment init");
      return await fetchListShipments({ orderId });
    },
    {
      onError: (err) => {
        console.log("- fetchListShipments error", err);
        setMsg(err);
      },
      onSuccess: (result) => {
        // console.log("- onSuccess result: ", result);
        let shipment = result?.shipments[0];
        if (shipment) {
          let shippingLabelName = `${shipment.orderNumber}.pdf`;

          setShipProcessResult({
            orderId: shipment.orderId,
            serviceCode: shipment.serviceCode,
            shipmentCost: shipment.shipmentCost,
            trackingNumber: shipment.trackingNumber,
            shippingLabelName,
          });
        }
      },
    }
  );

  const fetchLocalOrder = useMutation(
    async ({ orderId }) => {
      console.log("* fetchLocalOrder init");
      return await fetchWorkOrder({orderId});
    },
    {
      onMutate: () => msg && setMsg(null),
      onError: (err) => {
        console.log("[fetchLocalOrder] error", err);
        const retVal = errorHandler(err)
        setMsg(retVal);
      },
      onSuccess: async (result, variables) => {
        // console.log("[fetchLocalOrder] onSuccess result: ", result);
        if(result) {
          setLocalOrder(result);
          fetchOrderItems.mutate({ orderId: variables.orderId });
          console.log("[fetchLocalOrder] source: ", result?.source);
          if(result?.source !== 'manual' || _.isNil(result.source)) {
            sourceOrderRef.current = 'shipstation'
            fetchShipStationOrder.mutate({orderId: variables.orderId})
          }else {
            if(result?.source === 'manual') sourceOrderRef.current = 'manual';
          }
        } else {
          const queryParams = queryString.parse(props.location.search);
          console.log('[fetchLocalOrder] queryParams; ', queryParams)
          if(_.has(queryParams, 'source') && queryParams?.source) {
            sourceOrderRef.current = queryParams.source
            if(queryParams?.source==='shipstation'){
              fetchShipStationOrder.mutate({orderId: variables.orderId})
            }
          } else {
            setMsg("The order not exists");
          }

        }
      },
    }
  );

  const fetchOrderItems = useMutation(
    async ({ orderId }) => {
      console.log("* fetchOrderItems init");
      return await fetchPackingItems({ orderId });
    },
    {
      onError: (err) => {
        console.log("[fetchOrderItems] error", err);
        setMsg(err);
      },
      onSuccess: (result) => {
        // console.log("[fetchOrderItems] onSuccess result: ", result, Boolean(result.length));
        console.log("[fetchOrderItems] sourceOrderRef.current: ", sourceOrderRef.current);
        if(Boolean(result.length)){
          setOrderItems(result);
        } else {
          if(sourceOrderRef.current==='manual') orderItemHandler({type: 'add'})
        } 
      }
    }
  );

  useEffect(() => {
    console.log('* session validation hook ran');
    // console.log('- settings: ', settings);
    if(settings && !settings?.useUnlockedPrintflo){
      console.log('[Session Validation Hook] Subscription customer')
      
      const sessionValidateResult = sessionValidate({sessions, hostname});
      console.log("- sessionValidateResult: ", sessionValidateResult)
      if(sessionValidateResult?.message) setSessionMsg(sessionValidateResult.message);
      if(!sessionValidateResult?.result){
        isSessionValidRef.current = false;
        setDisableBatchQueueBtn(true);
      } else {
        isSessionValidRef.current = true;
        setDisableBatchQueueBtn(false)
      }
    } else {
      console.log('[Session Validation Hook] Unlocked version')
    }

    return () => setSessionMsg(null);

  // eslint-disable-next-line
  }, [sessions, hostname, settings])

  const onBarcodeScanned = async (data) => {
    console.log("* onBarcodeScanned init");
    console.log("[onBarcodeScanned] data: ", data);
    const { orderId } = barcodeScannedHandler(data);
    console.log("[onBarcodeScanned] orderId: ", orderId);
    if(orderId !== Number(orderIdRef.current)) {
      setLocalOrder(null);
      setShipstationOrder(null);
      setOrderItems([])
      orderId && history.push(`/workorder-details/${orderId}`);
    }
  };

  //onBarcodeScanned hook
  useEffect(() => {
    const listner = window.printflo_api && window.printflo_api.receive("serialport_readline", onBarcodeScanned)
    
    setTimeout(() => {
      _.isNil(window?.printflo_api) && socketOnBarcodeScanned();
    }, 1000);

    return () => {
      if(listner) listner();
      socket.removeAllListeners(["on-barcode-scanned"]);
    }
    // eslint-disable-next-line
  }, []);

  const socketOnBarcodeScanned = async () => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log("* on-barcode-scanned")
      console.log("[on-barcode-scanned] data: ", data);
      const { orderId } = barcodeScannedHandler(data);
      console.log("[onBarcodeScanned] orderId: ", orderId);
      if(orderId !== Number(orderIdRef.current)) {
        setLocalOrder(null);
        setShipstationOrder(null);
        setOrderItems([])
        orderId && history.push(`/workorder-details/${orderId}`);
      }
    });
  };

  //location.state hook
  useEffect(() => {
    console.log("* location hook init");
    console.log('[location hook] location: ', location)
    console.log('[location hook] params: ', params)
    console.log('[location hook] checking location state has shipment!')
    let orderId;
    if(_.has(params, 'orderId')) {
      orderId = orderIdRef.current = params.orderId;
      console.log('[location hook] call fetchLocalOrder')
      fetchLocalOrder.mutate({orderId})
    }

    if (_.has(props.location.state, "shipment")) {
      let shpmt = props.location.state.shipment;
      let shippingLabelName = `${shpmt.orderNumber}.pdf`;

      setShipProcessResult({
        orderId: shpmt.orderId,
        serviceCode: shpmt.serviceCode,
        shipmentCost: shpmt.shipmentCost,
        trackingNumber: shpmt.trackingNumber,
        shippingLabelName,
      });
    }
    // eslint-disable-next-line
  }, [location]);

  const orderItemHandler = ({ item, type }) => {
    console.log("* orderItemHandler init");
    console.log('[orderItemHandler] item: ', item)
    console.log('[orderItemHandler] type: ', type)
    setOrderItemMode(type);

    if (item) {
      setSelectedItem(item);
    } else {
      setSelectedItem(initialOrderItem);
    }

    setOrderItemModalIsOpen(true);
  };

  const updateLocalOrder = useMutation(
    async ({ condition, update }) => {
      return await updateOrder({ condition, update });
    },
    {
      onSuccess: (data) => {
        console.log("[updateLocaOrder:mutate:onSuccess] data: ", data);
        setLocalOrder(data);
      },
    }
  );

  const reAssignBin = useMutation(
    async () => {
      console.log("* reAssignBin init");
      let volumn = _.sumBy(orderItems, (item) => {
        return item.isActive && item.quantity;
      });
      console.log("[reAssignBin] volumn: ", volumn);
      return await assignBin(orderIdRef.current, volumn);
    },
    {
      onMutate: () => {
        setModalPreloaderIsOpen(true);
        queryClient.cancelQueries(["localOrder", orderIdRef.current]);
        const { bin: previousBin } = localOrder;
        console.log("previousBin: ", previousBin);
        return { previousBin };
      },
      onSuccess: async (data, variables, context) => {
        console.log("[reAssignBin] onSuccess data: ", data);
        console.log("[reAssignBin] onSuccess variables: ", variables);
        console.log("[reAssignBin] onSuccess context: ", context);
        setModalPreloaderIsOpen(false);
        await updateBin({
          condition: { binCode: context.previousBin },
          update: { $set: { isActive: false } },
        });

        toast.info(`${data.binCode} is assigned, previous bin ${context.previousBin} is in-activated`, {
          position: 'bottom-right'
        })
        
        queryClient.invalidateQueries(["localOrder", orderIdRef.current]);

        setLocalOrder((current) => {
          return {...current, bin: data.binCode}
        })

      },
    }
  );

  const binHandler = useMutation(
    async (orderQty) => {
      console.log("* binHandler init");
      console.log("[binHandler] orderQty: ", orderQty);
      console.log("[binHandler]  binAssignmentType: ", settings?.binAssignmentType);
      let orderId = orderIdRef.current;
      if(settings?.binAssignmentType !== 'hanger') {
        if (orderQty === 1 && !_.isNil(localOrder?.bin)) {
          return updateBin({
            condition: { binCode: localOrder.bin },
            update: {
              $set: {
                isActive: false,
              },
            },
          }).then(async (result) => {
            console.log("[binHandler:updateBin] result", result);
            return await updateOrder({
              condition: { orderId },
              update: {
                $set: {
                  bin: null,
                  quantity: orderQty,
                },
              },
            });
          });
        } 
        else
        if(orderQty > 1 && _.isNil(localOrder?.bin)) {
          console.log("[binHandler]  case of binAssignmentType is not hanger");
          return await assignBin(orderId, orderQty);
        }
      } else {
        console.log("[binHandler]  case of binAssignmentType is hanger");
        return await assignBin(orderId, 1)
      }
    },
    {
      onSuccess: async (data) => {
        console.log("[binHandler] onSuccess data", data);
        if(data) {
          if(data?.binCode) {
            setLocalOrder((current) => {
              return {...current, bin: data.binCode}
            })
          } 
          else
          if(data?.bin) {
            setLocalOrder((current) => {
              return {...current, bin: data.bin}
            })
          } 
        } 
        
      },
    }
  );

  const updateOrderItem = useMutation(
    async (orderItem) => {
      console.log("* updateOrderItem:mutate init");
      console.log("[updateOrderItem] orderItem: ", orderItem);
      let { sku, quantity, pickedQty, checkedOutQty, isActive } = orderItem;
      console.log("[updateOrderItem] checkedOutQty:arg, isEmpty ", checkedOutQty, _.isEmpty(checkedOutQty));
      if(_.isEmpty(checkedOutQty)) checkedOutQty = 0;
      return await updatePackingItem({
        condition: { id: orderItem._id },
        update: {
          sku,
          quantity,
          pickedQty,
          checkedOutQty,
          isActive,
        },
      });
    },
    {
      onError: (err) => {
        console.log("[updateOrderItem] onError: ", err);
      },
      onSuccess: async (data) => {
        console.log("[updateOrderItem] onSuccess data: ", data);
        let orderId = orderIdRef.current;
        
        // await fetchOrderItems.mutate({ orderId });
        
        let currentOrderItems = _.cloneDeep(orderItems);
        console.log("[updateOrderItem] currentOrderItems: ", currentOrderItems);
        let foundIndex = _.findIndex(currentOrderItems, { _id: data._id });
        console.log("[updateOrderItem] foundIndex: ", foundIndex);

        let updatedOrderItem = { ...currentOrderItems[foundIndex], ...data};
        console.log('[updateOrderItem] updatedOrderItem: ', updatedOrderItem)
        currentOrderItems[foundIndex] = updatedOrderItem;
        setOrderItems(currentOrderItems);

        let _orderQty = _.sumBy(
          currentOrderItems,
          (item) => item.isActive && parseInt(item.quantity)
        );
        console.log(`[updateOrderItem] _orderQty: ${_orderQty}`);
        if(_.isNaN(_orderQty)) _orderQty = 0;  

        await binHandler.mutate(_orderQty);

        let _checkedOutQty = _.sumBy(
          currentOrderItems,
          (item) => item.isActive && parseInt(item.checkedOutQty)
        );
        console.log(`[updateOrderItem] _checkedOutQty: ${_checkedOutQty}`);
        if(_.isNaN(_checkedOutQty)) _checkedOutQty = 0;  
        
        let _pickedQty = _.sumBy(
          currentOrderItems,
          (item) => item.isActive && parseInt(item.pickedQty)
        );
        console.log(`[updateOrderItem] _pickedQty: ${_pickedQty}`);
        if(_.isNaN(_pickedQty)) _pickedQty = 0;  

        let payload = {
          condition: { orderId },
          update: {
            checkedOutQty: _checkedOutQty,
            quantity: _orderQty,
            pickedQty: _pickedQty,
          },
        };
        await updateLocalOrder.mutate(payload);
      },
    }
  );

  const addOrderItem = useMutation(
    async (update) => {
      return await addPackingItem({...update});
    },
    {
      onError: (err) => {
        console.log("[WorkOrderDetails:addOrderItem:mutate] err: ", err);
        toast.error(err, {position: "bottom-right"})
      },
      onSuccess: async (data) => {
        // console.log("[WorkOrderDetails:addOrderItem:onSuccess] data: ", data);
        let orderId = orderIdRef.current;
        console.log("[WorkOrderDetails:addOrderItem:onSuccess] orderId: ", orderId);
        await fetchPackingItems({ orderId }).then(async (results) => {
          console.log("[WorkOrderDetails:addOrderItem:fetchPackingItems] results: ", results);
          setOrderItems(results);

          let currentOrderItems = _.cloneDeep(results);

          return updateManyPackingItem({
              condition: { orderId },
              update: {
                '$set': { count: currentOrderItems.length },
              },
            })
            .then(async (result) => {
              // console.log("[WorkOrderDetails:addOrderItem:updateManyPackingItem] result", result);

              let _orderQty = _.sumBy(currentOrderItems, (item) => {
                return item.isActive && parseInt(item.quantity);
              });
              await binHandler.mutate(_orderQty);

              let _checkedOutQty = _.sumBy(currentOrderItems, (item) => {
                return item.isActive && parseInt(item.checkedOutQty);
              });

              let _quantity = _.sumBy(currentOrderItems, (item) => {
                return item.isActive && parseInt(item.quantity);
              });

              console.log("[WorkOrderDetails:addOrderItem] _checkedOutQty: ", _checkedOutQty);
              console.log("[WorkOrderDetails:addOrderItem] _quantity: ", _quantity);

              let palyload = {
                condition: { orderId },
                update: {
                  checkedOutQty: _checkedOutQty,
                  quantity: _quantity,
                },
              };

              await updateLocalOrder.mutate(palyload);
            });
        });
      },
    }
  );

  const orderItemSubmitHandler = async (orderItem) => {
    console.log("* orderItemSubmitHandler init");
    console.log("[orderItemSubmitHandler] orderItemMode: ", orderItemMode);
    // console.log("[orderItemSubmitHandler] orderItem: ", orderItem);
    setOrderItemModalIsOpen(false);
    if (orderItemMode === "edit") {
      await updateOrderItem.mutate(orderItem);
    } else {
      // console.log("[orderItemSubmitHandler:add] orderIem: ", orderItem);
      const { orderId, orderNumber, source } = shipstationOrder || localOrder;

      const len = Boolean(orderItems.length) ? orderItems.length : 0;
      // console.log("orderItemsLen:", len);
      orderItem?._id && delete orderItem["_id"];
      const { component, description, imageUrl } = orderItem._item;
      const orderItemId = Math.floor(Math.random()*10000000).toString()
      let update = {
        ...orderItem,
        orderId,
        orderNumber,
        orderItemId,
        component,
        count: len + 1,
        sequence: Boolean(len) ? orderItems[len - 1].sequence + 1 : 1,
        checkedOutQty: 0,
        pickedQty: 0,
        name: description,
        isBundle: false,
        imageUrl: imageUrl,
        source
      };

      console.log("[orderItemSubmitHandler:add] update:payload", update);
      addOrderItem.mutate(update);
    }
  };


  const printShippingLabel = () => {
    console.log("* printShippingLabel init");
    let { shippingLabelName, labelData } = shipProcessResult;
    console.log("- shippingLabelName", shippingLabelName);
    console.log("- labelData", labelData);
    if (shippingLabelName) {
      if(window?.printflo_api) {
        window.printflo_api._shippingPrint({shippingLabelName, labelData});
      } else {
        socket.emit("on-shipping-label-print", {filename: shippingLabelName}, (callback) => {
          console.log("[printShippingLabel] callback: ", callback)
          if(callback?.message) {
            toast.info(callback?.message, {
              position: "bottom-right"
            })
          }
        });
      }
    }
  };


  const showEditItemModal = ({ item, index }) => {
    console.log("* showEditItemModal init");
    // console.log('[showEditItemModal] item: ', item)
    // console.log('- index: ', index)
    const _item = item?._item ?? item._item;
    // console.log('- _item: ', _item)
    if (_item) {
      setSelectedItem(_item);
      componentValueRef.current = _item?._component?.sku
      ? _item._component.sku
      : _item?.component
      ? _item.component
      : "";
      setTimeout(() => {
        setItemModalIsOpen(true);
      }, 500) 
    } else {
      console.log("- item info null");
      toast.info("Item profile not defined.");
    }
  };

  const updateItem = useMutation(
    async ({ condition, update }) => {
      console.log("* updateItem init")
      return await submitItem({ condition, update });
    },
    {
      onMutate: async () => {
        setModalPreloaderIsOpen(true);
        if(isEditItemSubmitRef.current) isEditItemSubmitRef.current = false;
        await queryClient.cancelQueries(["orderItems", orderIdRef.current]);
      },
      onError: (error) => {
        console.log("[updateItem] error", error);
        setModalPreloaderIsOpen(false)
        const retVal = errorHandler(error);
        toast.error(retVal, {
          position: "bottom-right",
        });
      },
      onSuccess: async (data) => {
        // console.log("[updateItem] onSuccsess data: ", data);
        // queryClient.invalidateQueries(["orderItems", orderIdRef.current]);
        await fetchOrderItems.mutate({ orderId: orderIdRef.current });
        setModalPreloaderIsOpen(false);
      },
    }
  );

  const editItemSubmitHandler = () => {
    console.log("* editItemSubmitHandler init");
    console.log("[editItemSubmitHandler] selectedItem: ", selectedItem);
    isEditItemSubmitRef.current = true

    itemModalIsOpen && setItemModalIsOpen(false);

    delete selectedItem?.stock;
    delete selectedItem?._graphics;
    delete selectedItem?._inventoryArea;

    updateItem.mutate({
      condition: { sku: selectedItem.sku },
      update: selectedItem,
    });
  };

  const removeLocalOrder = () => {
    console.log("* removeLocalOrder init");
    let orderId = orderIdRef.current;
    const confirm = window.confirm(
      "Confirm to remove this order. Restoring data is not available after removing an order"
    );
    if (confirm) {
      setModalPreloaderIsOpen(true);
      deleteLocalOrder(orderId)
        .then(async (result) => {
          // console.log("[removeLocalOrder] deleteLocalOrder result: ", result)
          console.log("[removeLocalOrder] orderId: ", orderId);
          return await deletePackingItem(orderId);
        })
        .then(async () => {
          if (localOrder?.bin) {
            await updateBin({
              condition: { binCode: localOrder.bin },
              update: { $set: { isActive: false } },
            });
          }

          setModalPreloaderIsOpen(false);
          history.push("/work-orders");
        });
    }
  };

  const createShipLabelErrHandler = (error) => {
    return new Promise((resolve, reject) => {
      let errorMsg;
      try {
        if (_.has(error.response.data, "ExceptionMessage")) {
          const exceptionMessage = error.response.data.ExceptionMessage;
          console.log("exceptionMessage", exceptionMessage);
          resolve({ message: exceptionMessage });
        } else {
          errorMsg = errorHandler(error);
          console.log("errorMsg", errorMsg);
          resolve({ message: errorMsg });
        }
      } catch (error) {
        console.log("catch error", error);
        errorMsg = errorHandler(error);
        console.log("errorMsg", errorMsg);
        reject({ message: errorMsg });
      }
    });
  };

  const updateCheckedOutQtyAll = () => {
    console.log("* updateCheckedOutQtyAll init")
    return new Promise((resolve, reject) => {
      let promises = orderItems.map((item) => {
        const payload = {
          condition: {
            id: item._id,
          },
          update: {
            $set: { checkedOutQty: item.quantity },
          },
        };
        return updatePackingItem(payload);
      });

      Promise.all(promises)
        .then(async (response) => {
          console.log("[updateCheckedOutQtyAll:updatePackingItem] response: ", response);
          console.log("[updateCheckedOutQtyAll] orderIdRef.current: ", orderIdRef.current);
          console.log("[updateCheckedOutQtyAll] localOrder: ", localOrder);
          
          let orderId = orderIdRef.current || shipstationOrder?.orderId || localOrder?.orderId;
          console.log("[updateCheckedOutQtyAll] orderId: ", orderId);
          
          let sumCheckedOutQty = _.sumBy(orderItems, "quantity");
          console.log("[updateCheckedOutQtyAll] sumCheckedOutQty: ", sumCheckedOutQty);
          if(orderId) {
            await updateOrder({
              condition: { orderId },
              update: { $set: { checkedOutQty: sumCheckedOutQty } },
            }).then(async (result) => {
              console.log("[updateCheckedOutQtyAll:updateOrder] result: ", result);
              resolve(true);
            });
          } else {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(errorHandler(error));
        });
    });
  };

  const postHandleShipment = useMutation(
    async () => {
      console.log("* postHandleShipment init");
      let orderId = orderIdRef.current;
      return await updateCheckedOutQtyAll()
        .then((response) => {
          console.log("[postHandleShipment:updateCheckedOutQtyAll] response", response);

          return updateManyPackingItem({
            condition: { orderId },
            update: { $set: { isActive: false } },
          });

        })
        .then(async () => {
          let { bin } = localOrder;
          if (!_.isNil(bin)) {
            await updateBin({
              condition: { binCode: bin },
              update: { $set: { isActive: false } },
            });
          }
        });
    },
    {
      onMutate: () => {
        setModalPreloaderIsOpen(true);
      },
      onSuccess: async () => {
        setModalPreloaderIsOpen(false);
        let orderId = orderIdRef.current;
        updateLocalOrder.mutate({
          condition: { orderId },
          update: { orderStatus: "shipped" },
        });
        // queryClient.invalidateQueries(["orderItems", orderId]);

        await fetchOrderItems.mutate({ orderId });

        const confirm = window.confirm('Click OK to print packing slip')
        if(confirm) handlePrintPackingSlip()
      },
    }
  );

  const createShippingLabelHandler = useMutation(
    async ({ temp, orderNumber }) => {
      console.log("* WorkOrderDetails:createShippingLabelHandler init");
      return await createShippingLabel(temp, orderNumber, settings?.testLabel);
    },
    {
      onMutate: async () => {
        setModalPreloaderIsOpen(true);
        await queryClient.cancelQueries(["ssOrder", orderIdRef.current]);
      },
      onError: async (error) => {
        let errResponse = await createShipLabelErrHandler(error);
        console.log("[WorkOrderDetails:createShippingLabelHandler] errResponse: ", errResponse);
        setModalPreloaderIsOpen(false);

        toast.error(errResponse.message, {
          position: "bottom-right",
          autoClose: false,
        });
      },
      onSuccess: (result, { orderNumber }) => {
        setModalPreloaderIsOpen(false);

        console.log("[WorkOrderDetails:createShippingLabelHandler] result: ", result);
        // console.log("- orderNumber", orderNumber);
        let shippingLabelName = `${orderNumber}.pdf`;
        // let { shipmentCost, trackingNumber } = result;
        setShipProcessResult({...result, shippingLabelName})

        if (!settings?.testLabel) {
          postHandleShipment.mutate();
        } //end if

        queryClient.invalidateQueries(["ssOrder", orderIdRef.current]);
      },
    }
  );

  const handleShipment = async () => {
    console.log("* WorkOrderDetails hanldeShipment init");
    let flag = _.isNil(shipstationOrder) ? "check-out" : "creation of shipping label";        
    let msg = `The purpose of batch shipment is to automatically process a ${flag} without individual scanning of items. Do you want to run?`;
    let confirm = window.confirm(msg);
    if (confirm) {
      if(shipstationOrder) {
        if (_.includes(shipstationOrder?.orderStatus, "awaiting")) {
          // if (settings?.orderStatus === "awaiting_shipment") {
          // create shipping label args
          let {
            orderId,
            orderNumber,
            carrierCode,
            serviceCode,
            confirmation,
            weight,
          } = shipstationOrder;
  
          let temp = {
            orderId,
            carrierCode,
            serviceCode,
            confirmation,
            weight,
            shipDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          };
  
          await createShippingLabelHandler.mutate({ temp, orderNumber });

        } else {
          Boolean(orderItems.length) && postHandleShipment.mutate();
        }
      } else {
        Boolean(orderItems.length) && postHandleShipment.mutate();
      }
    }
  };

  const handlePrintPackingSlip = async () => {
    console.log("* handlePrintPackingList init");
    setModalPreloaderIsOpen(true);
    console.log("[handlePrintPackingSlip] shipstationOrder: ", shipstationOrder);
    let warehouseId;
    if(shipstationOrder) warehouseId = shipstationOrder?.advancedOptions?.warehouseId;
    console.log("[handlePrintPackingSlip] warehouseId: ", warehouseId);
    
    if (warehouseId) {
      try {
        let warehouse = await getWarehouse(warehouseId);
        // console.log("[handlePrintPackingSlip:getWarehouse] warehouse: ", warehouse);
        if(warehouse?.originAddress) setShipFrom(warehouse.originAddress);
        setModalPreloaderIsOpen(false);
      } catch (err) {
        console.log("[handlePrintPackingSlip:getWarehouse] err: ", err);
        setModalPreloaderIsOpen(false);
        toast.error(err, {position: "bottom-right"});
      }
    } else {
      setModalPreloaderIsOpen(false);
    }

    const printContent = document.getElementById("print-section").innerHTML;

    const frame1 = document.createElement("iframe");
    frame1.name = "frame3";
    frame1.style.position = "absolute";
    frame1.style.top = "-100000000px";
    document.body.appendChild(frame1);

    const frameDoc = frame1.contentWindow
      ? frame1.contentWindow
      : frame1.contentDocument.document
      ? frame1.contentDocument.document
      : frame1.contentDocument;

    frameDoc.document.open();
    frameDoc.document.write("<html><head><title>Print Picking Slip</title>");
    frameDoc.document.write("<style>");
    frameDoc.document.write(
      "body,table,span{font-family:Arial,Helvetica,sans-serif;font-size:7pt}"
    );
    frameDoc.document.write(
      ".text-center{text-align:center}.text-right{text-align:right}"
    );
    frameDoc.document.write("table th{text-align:center}");
    frameDoc.document.write(
      "table{border-collapse:collapse;border:none;width:100%}"
    );
    frameDoc.document.write(
      "table thead th{border:#666 .5px solid;padding:5px;}"
    );
    frameDoc.document.write("table thead td{border:none;padding:7px;}");
    frameDoc.document.write(".print-section_items{margin-top:10px;}");
    frameDoc.document.write(".customer-guide-wrapper{margin-top: 20px}");
    frameDoc.document.write(
      "span.block{display:block;padding:10px 0;font-size:9pt}"
    );
    frameDoc.document.write("</style>");
    frameDoc.document.write('</head><body onafterprint="window.close()">');
    frameDoc.document.write(printContent);
    frameDoc.document.write("</body></html>");
    frameDoc.document.close();
    setTimeout(() => {
      window.frames["frame3"].focus();
      window.frames["frame3"].print();
      document.body.removeChild(frame1);
    }, 1000);
  };

  const handleGeneratePMT = async (e, item) => {
    console.log("* handleGeneratePMT init");
    // console.log("[handleGeneratePMT ] item: ", item);
    e.preventDefault();
    setModalPreloaderIsOpen(true);
    try {
      const result = await generatePMTHandler(item, settings);
      console.log("[handleGeneratePMT] result: ", result);
      const { pretreatmentBarcode, message } = result;
      if (pretreatmentBarcode) {
        setSelectedItem({
          ...item,
          pretreatmentBarcode: result.pretreatmentBarcode,
        });
        updateItem.mutate({
          condition: { sku: item.sku },
          update: { pretreatmentBarcode },
        });
      }
      if (message) {
        toast.error(message, {
          position: "bottom-right",
        });
      }
      setModalPreloaderIsOpen(false);
    } catch (error) {
      console.log("[handleGeneratePMT] generatePMTHandler error: ", error);
      setModalPreloaderIsOpen(false);
      toast.error(error, {
        position: "bottom-right",
      });
    }
  };

  const workOrderStatusHandler = useDebouncedCallback(
    ({ workOrderStatus }) => {
      console.log("* workOrderStatusHandler");
      let orderId = orderIdRef.current
      console.log("- orderId: ", orderId);
      console.log("- workOrderStatus: ", workOrderStatus);
      let payload = {
        condition: { orderId },
        update: { workOrderStatus },
      };
      updateLocalOrder.mutate(payload);
    },
    [500]
  );

  const WorkOrderStatus = React.memo(({ workOrderStatuses, order }) => {
    // console.log('* OrderStatus init')
    let workOrderStatus = order?.workOrderStatus ? order.workOrderStatus : null;
    // let orderId = order?.orderId;

    const styled = { backgroundColor: "#E1F2F6", color: "#1A567E" };

    const status = _.find(workOrderStatuses, { name: workOrderStatus });
    if (status) {
      // console.log('- status: ', status);
      styled.backgroundColor = status.color;
      styled.color = "#fff";
    }

    return (
      <select
        defaultValue={workOrderStatus ? workOrderStatus : ""}
        onChange={(e) => {
          workOrderStatusHandler({ workOrderStatus: e.target.value });
        }}
        className="work-order-status ml-10"
        style={styled}
      >
        <option
          value=""
          style={{ backgroundColor: "#E1F2F6", color: "#1A567E" }}
        >
          = Order Status
        </option>
        {workOrderStatuses.map((s) => {
          return (
            <option
              value={s.name}
              key={s._id}
              style={{
                backgroundColor: s.color,
                color: "#fff",
              }}
            >
              {s.name}
            </option>
          );
        })}
      </select>
    );
  });

  const handleSearch = async (searchTxt) => {
    console.log("* handleSearch init");
    console.log("[handleSearch] searchTxt", searchTxt);
    console.log("[handleSearch] selectedSearchOption", selectedSearchOption);
    if (!_.isEmpty(searchTxt)) {
      let payload = {[selectedSearchOption]: searchTxt};
      setModalPreloaderIsOpen(true);
      try {
        const result = await getOrderId(payload)
        // console.log("[handleSearch:getOrderId] result: ", result);
        setModalPreloaderIsOpen(false);
        if(_.isNil(result)) {
          toast.error("Not found!", {
            position: "bottom-right",
            pauseOnFocusLoss: false,
          });
          return;
        }
        if (result?.orderId) {
          toast.info(`Moving the order details: ${searchTxt}`, {
            position: "bottom-right",
            onClose: () =>
              history.replace(`/workorder-details/${result.orderId}`),
            autoClose: 2000,
          });
        } 
      } catch (error) {
        console.log("[handleSearch:getOrderId] error: ", error);
        setModalPreloaderIsOpen(false);
        toast.error(error, {
          position: "bottom-right",
          pauseOnFocusLoss: false,
          // onClose: () => setSearchTxt("")
        });
        return false;
      }
    }    
  };

  const onAfterCloseModal = () => {
    console.log("* onAfterCloseModal init");
    // console.log("[onAfterCloseModal] componentValueRef: ", componentValueRef.current);
    if(!_.isEmpty(componentValueRef.current))  componentValueRef.current= "";
    if(isEditItemSubmitRef.current) fetchOrderItems.mutate({ orderId: orderIdRef.current });
  };

  const queuePrintHandler = async () => {
    console.log("* queuePrintHandler init")
    console.log('[queuePrintHandler] orderItems: ', orderItems.length)
    setDisableBatchQueueBtn(true);
    isLoadingBatchQueueRef.current = true;
    setMsg('Batch queue print init, wait...')
    let tempItems = [];
    let tempMsg = '';
    onQueueCadlinkResultCount.current = 0;
    bannerVariantRef.current = 'info'

    // required props
    // orderId, orderItemId, ripEnv/printMode, selectedPosition, selectedCadlinkQueue, updatedPrinted, printQty

    // selectedCadlinkQueue
    let selectedCadlinkQueue;
    console.log('[queuePrintHandler] hostname: ', hostname);
    console.log('[queuePrintHandler] settings?.cadLinkQueues: ', settings?.cadLinkQueues);
    if(_.isNil(hostname)) {
      tempMsg = "Hostname is null/undefined."
      bannerVariantRef.current = 'danger';
      setMsg(tempMsg);
      setDisableBatchQueueBtn(false);
      isLoadingBatchQueueRef.current = false;
      return
    }

    if(hostname && Boolean(settings?.cadLinkQueues?.length)) {
      for (let index = 0; index < settings.cadLinkQueues.length; index++) {
        const q = settings.cadLinkQueues[index];
        for (let i = 0; i < q?.hostnames.length; i++) {
          const h = q?.hostnames[i];
          // console.log(index, i, h)
          if(hostname === h) {
            selectedCadlinkQueue = q;
            break;
          }
        }
      }
    } else {
      tempMsg = tempMsg + 'Required at least a CADlink queue method!';
      bannerVariantRef.current = 'danger';
      setMsg(tempMsg);
      setDisableBatchQueueBtn(false);
      isLoadingBatchQueueRef.current = false;
      return;
    }
    console.log('[queuePrintHandler] selectedCadlinkQueue: ', selectedCadlinkQueue)
    if(_.isNil(selectedCadlinkQueue)) {
      bannerVariantRef.current = "danger";
      setMsg('CADlink queue is required; this station may not registered to queue')
      setDisableBatchQueueBtn(false);
      isLoadingBatchQueueRef.current = false;
      return;
    }
    // prep data by graphics array
    const countOrderItems = orderItems.length;
    onQueueCadlinkCount.current = countOrderItems;
    console.log('[queuePrintHandler] loop order items for tempItems')
    for(let i = 0;i<countOrderItems;i++) {
      let item = orderItems[i]

      console.log(`[queuePrintHandler] ${i}/${countOrderItems} sku: ${item.sku}`);

      const {_id, orderId, orderItemId, quantity, printed} = item;
      let ripEnv = item?._item?.ripEnv;
      let graphics = item?._item?._graphics ? item?._item?._graphics : [];
      // console.log('-graphics: ', graphics.length);
      console.log('[queuePrintHandler] looping graphics in order items')
      if(Boolean(graphics?.length)) {
        for(let g of graphics) {
          // console.log(g)
          let graphicPosition = g?.graphicPosition ? g?.graphicPosition : ""; 
          let tempPrinted = _.find(printed, (value) => {
            return _.includes(value.position.toLowerCase(), graphicPosition.toLowerCase())
          }) || {
            qty: 0,
          };
          console.log('[queuePrintHandler] tempPrinted: ', tempPrinted)
          
          for (let index = 0; index < [...Array.from(Array(quantity).keys())].length; index++) {
            let updatePrinted;
            // console.log('index: ', index, index === 0)
            if(index===0) {
              if (tempPrinted.qty < quantity) {
                updatePrinted = {
                  _id,
                  graphicPosition,
                  hostname,
                  username: user?.username,
                  printedQty: quantity
                };
              }
            }
            tempItems = [...tempItems, {orderId, orderItemId, ripEnv, selectedPosition: g, selectedCadlinkQueue, updatePrinted}]
          }
        }

        
      } else {
        tempMsg = `sku ${item.sku} - No graphic info found, continue to next.`;
        bannerVariantRef.current = 'danger';
        setMsg(tempMsg);

      }
    }// end of loop

    // if(!_.isEmpty(tempMsg)) {
    //   console.log('[queuePrintHandler] tempMsg: ', tempMsg);
    //   bannerVariantRef.current = 'danger';
    //   setMsg(tempMsg);
    //   return
    // }

    console.log('[queuePrintHandler] tempItems: ', tempItems.length)

    let i = 0, len = tempItems.length, totalPrinted = 0;

    if(!Boolean(len) || _.isNil(tempItems) || _.isEmpty(tempItems)) {
      tempMsg = 'Not found to queue print';
      bannerVariantRef.current = 'danger';
      setMsg(tempMsg);
      return
    }

    function loop() {
      return new Promise(async (resolve, reject) => {
        const item = tempItems[i];
        console.log(`[queuePrintHandler:loop] ${i+1}/${len} sku: ${item?.selectedPosition?.sku}, orderItemId: ${item?.orderItemId}`);
        console.log(`[queuePrintHandler:loop] updatePrinted: `, item.updatePrinted);
        let response;
        
        try {
          if(window?.printflo_api) {
            response = await window.printflo_api._queueCADlink(item);
          } else {
            const promise = () => {
              return new Promise((resolve, reject) => {
                socket.emit("on-queue-CADlink", item, (response) => {
                  console.log('[queuePrint:CADlink] response: ', response);
                  resolve(response);
                });

              })
            }
            response = await promise();
          }
          
          console.log('[queuePrintHandler:loop] response: ', response);
          
          if(response?.error) {
            bannerVariantRef.current = "danger"
            setMsg(`${i+1}/${len} ${response.message}`);
            i=len;
            setDisableBatchQueueBtn(false)
            reject()
            return
          } else {
            bannerVariantRef.current = "info"
            setMsg(`${i+1}/${len} ${response.message}`);
            if(response?.printed) {
              totalPrinted = totalPrinted + response.printed[0].qty
              setOrderItems(current => {
                let tempOrderItems = _.cloneDeep(current);
                const foundOrderItemIndex = _.findIndex(tempOrderItems, {orderItemId: item.orderItemId})
                if(foundOrderItemIndex !== -1) {
                  tempOrderItems[foundOrderItemIndex] = {...tempOrderItems[foundOrderItemIndex], printed: response.printed}
                  return tempOrderItems;
                }
              });


            }
            resolve();
          }

          i = i + 1
          
          if(i<len){
            await loop();
          } else {
            console.log("[queuePrintHandler] count:end of loop: ", i);
            console.log('[queuePrintHandler] totalPrinted: ', totalPrinted);
            if(totalPrinted > 0){
              setLocalOrder(current => ({...current, printedQty: totalPrinted}))
            }
            setDisableBatchQueueBtn(false);
            toast.info('Done batch queue', {
              position: "bottom-right",
              onClose: () => setMsg(null)
            })
            totalPrinted = 0
          }

        } catch (error) {
          const retVal = errorHandler;
          console.error("[queuePrintHandler:loop] error:retVal: ", retVal);
          bannerVariantRef.current = "danger"
          setMsg(retVal)
        }
      })
    }

    if(i<len) await loop();
      
  }

  return (
    <>
      <Title title={"Order Details"} />
      <ToastContainer theme="dark" />
      <PageMainHeader title={title} user={user} settings={settings} />
      <section className="primary">
        {sessionMsg && (
          <Banner className='mb-10' variant="warning">{sessionMsg}</Banner>
        )}
        {_.isEmpty(localOrder) && shipstationOrder && (
          <Banner className="lg-banner">Not a synced order</Banner>
        )}
        {localOrder && _.isNil(shipProcessResult) && (
          <PageMenuHeader>
            <div className="btn-menu-list">
              {user && user?.role === "admin" && (
                <Button
                  variant="danger"
                  className="swing-icon"
                  onClick={() => removeLocalOrder()}
                  leftGlyph={<FaRemoveFormat />}
                >
                  Remove this order
                </Button>
              )}
              <Button
                variant="primary"
                className="swing-icon"
                onClick={() => orderItemHandler({ type: "add" })}
                leftGlyph={<FaAddressBook />}
              >
                Add an item
              </Button>
              <Button
                variant="primary"
                // className={`swing-icon ${
                //   !_.isNil(shipProcessResult) ? "hidden" : ""
                // }`}
                className={`swing-icon`}
                onClick={() => handleShipment()}
                disabled={!_.isNil(shipProcessResult) || (shipstationOrder && shipstationOrder?.orderStatus === 'shipped') || localOrder?.orderStatus === 'shipped' || !isSessionValidRef.current}
                leftGlyph={<FaShip />}
              >
                Batch shipment
              </Button>
              {settings?.printPackingSlip && (
                <Button
                  variant="primary"
                  onClick={() => handlePrintPackingSlip()}
                  className="swing-icon"
                  leftGlyph={<FaPrint />}
                >
                  Print packing slip
                </Button>
              )}
              {/* {localOrder?.bin && ( */}
                <Button
                  variant="primary"
                  className="swing-icon"
                  onClick={() => {
                    let confirm = window.confirm("Confirm re-assign Bin?");
                    if (confirm) reAssignBin.mutate();
                  }}
                  leftGlyph={<FaCreativeCommonsSampling />}
                >
                  Re-assign BIN
                </Button>
              {/* )} */}
              {(settings?.defaultIntegratedAutomation.includes('CAD') || Boolean(settings?.cadLinkQueues.length)) && (
                <Button
                  variant="primary"
                  className="swing-icon"
                  onClick={queuePrintHandler}
                  leftGlyph={<FaPrint />}
                  disabled={disableBatchQueueBtn}
                >
                  Batch Queue Print(CADlink)
                </Button>
              )}
              {settings?.useWorkOrderStatus && localOrder && (
                <WorkOrderStatus
                  workOrderStatuses={settings?.workOrderStatuses}
                  order={localOrder}
                />
              )}
            </div>
            <div className="align-right_container">
                <Select
                  className="search-select"
                  onChange={(value) => {
                    console.log("- onChange args:", value);
                    setSelectedSearchOption(value);
                  }}
                  value={selectedSearchOption}
                  placeholder="Search Options"
                  aria-labelledby="Search options"
                >
                  <Option value="orderNumber">Order Number</Option>
                  <Option value="bin">Bin</Option>
                </Select>
                <Search
                  handleSearch={handleSearch}
                  placeholderText="Search"
                />
              </div>

          </PageMenuHeader>
        )}
        {shipProcessResult && (
          <div className="title-content-list card">
            <div className="title-content">
              <span className="title">Shipment Cost</span>
              <span className="content">
                {shipProcessResult?.shipmentCost?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Tracking Number</span>
              <span className="content">
                {shipProcessResult?.trackingNumber}
              </span>
            </div>
            <div className="title-content ml-10">
              <span className="title">Print Label</span>
              <IconButton
                className="swing-icon"
                onClick={() => printShippingLabel()}
                aria-labelledby="Print a shipping label"
              >
                <FaPrint />
              </IconButton>
            </div>
          </div>
        )}
        {(shipstationOrder || localOrder) && (
          <WorkOrderDetailsHeader
            ssOrder={shipstationOrder}
            localOrder={localOrder}
            isLoading={isLoading}
            settings={settings}
          />

        )}

        {msg && (
          <Banner variant={bannerVariantRef.current} className="mt-10">
            {msg} <GridLoader color={"#09804C"} size={5} width={20} loading={disableBatchQueueBtn}/>
          </Banner>
        )}

        {fetchOrderItems.isLoading ? (
          <Skeleton count={10} height={50} />
        ) : fetchOrderItems.isError ? (
          <Banner variant="danger">
            {fetchOrderItems?.error?.message}
          </Banner>
        ) : (
          settings && (
            <WorkOrderDetailsItems
              items={orderItems}
              handlePrintLabel={printOrderItemLabel}
              orderItemHandler={orderItemHandler}
              showEditItemModal={showEditItemModal}
              platens={platens}
              settings={settings}
              shipstationOrder={shipstationOrder}
              user={user}
              isSessionValid={isSessionValidRef.current}
            />
          )
        )}

      </section>

      <OrderItemModal
        modalIsOpen={orderItemModalIsOpen}
        setModalIsOpen={setOrderItemModalIsOpen}
        item={selectedItem}
        editOrderItemSubmitHandler={orderItemSubmitHandler}
        title={"Order Item"}
        mode={orderItemMode}
      />

      <ModalItem
        modalIsOpen={itemModalIsOpen}
        setModalIsOpen={setItemModalIsOpen}
        title={"Item Profile"}
        mode={"update"}
        item={selectedItem}
        setItem={setSelectedItem}
        submitHandler={editItemSubmitHandler}
        platens={platens}
        settings={settings}
        handleGeneratePMT={handleGeneratePMT}
        user={user}
        onAfterCloseModal={onAfterCloseModal}
        componentValueRef={componentValueRef}
      />

      {orderItems && (
        <PackingSlipTemplate
          shipFrom={shipFrom}
          ssOrder={shipstationOrder}
          localOrder={localOrder}
          orderItems={orderItems}
          settings={settings}
        />
      )}

      <ModalPreloader modalPreloaderIsOpen={modalPreloaderIsOpen} />

      {/* <pre>{order && JSON.stringify(order, null, 2)}</pre> */}
      {/* <pre>{localOrder && JSON.stringify(localOrder, null, 2)}</pre> */}
      {/* <pre>{isSessionValidRef && JSON.stringify(isSessionValidRef, null, 2)}</pre> */}
    </>
  );
};

export default WorkOrderDetails;
