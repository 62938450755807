import { updateOrder } from "./orders";
import _ from "lodash";
import { errorHandler } from "./errorHandler";
import axios from "axios";

export const updateBin = (data) => {
  console.info("* utils:updateBin init");
  return new Promise(async (resolve, reject) => {
    // console.info("data", data);
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._updateBin(data);
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = axios.post(`/api/bins/update`, data);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.info("[utils:updateBin] result: ", result);
      resolve(result);
    } catch (error) {
      console.error("[utils:updateBin] error", errorHandler(error));
      reject(errorHandler(error));
    }
  });
};

export const getBins = async (data) => {
  console.info("* utils:getBins init");
  return new Promise(async (resolve, reject) => {
    console.info("[utils:getBins] data", data);
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._fetchBins(data);
        if (_.isError(result)) throw result;
      } else {
        try {
          if (!_.has(data, "condition")) data = { condition: {} };
          const response = await axios.post(`/api/bins/get-bins`, data);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.info("[utils:getBins] result: ", result);
      resolve(result);
    } catch (error) {
      console.error("[utils:getBins] error", errorHandler(error));
      reject(errorHandler(error));
    }
  });
};

export const assignBin = async (orderId, volumn) => {
  console.info("* utils:assignBin init");
  return new Promise(async (resolve, reject) => {
    let result;
    let binCode;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._assignBin({ volumn });
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.post(`/api/bins/find-empty-bin`, {
            volumn,
          });
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      console.info("[utils:updateBin:_assignBin] result: ", result);
      if (!_.isNil(result?.binCode)) binCode = result.binCode;
    } catch (error) {
      console.error("[utils:updateBin:_assignBin] error", errorHandler(error));
      reject(errorHandler(error));
      return;
    }
    console.log("[[utils:updateBin] binCode: ", binCode);

    if (binCode) {
      updateOrder({
        condition: { orderId },
        update: { $set: { bin: binCode } },
      })
        .then(async () => {
          return await updateBin({
            condition: { binCode },
            update: { $set: { isActive: true } },
          });
        })
        .then((res) => {
          console.log("[assignBin] updated bin");
          // console.log("[assignBin] updated bin result: ", res);
          resolve({ binCode });
        })
        .catch((error) => {
          console.log("[assignBin] error: ", error);
          reject(errorHandler(error));
        });
    } else {
      reject(false);
    }
  });
};

export const resetBins = async () => {
  console.info("* utils:resetBins init");
  return new Promise(async (resolve, reject) => {
    let result;
    try {
      if (window?.printflo_api) {
        result = await window.printflo_api._resetBins();
        if (_.isError(result)) throw result;
      } else {
        try {
          const response = await axios.get(`/api/bins/reset`);
          result = response?.data;
        } catch (error) {
          throw error;
        }
      }
      // console.info("[utils:resetBins] result: ", result);
      resolve(result);
    } catch (error) {
      console.error("[utils:resetBins] error", errorHandler(error));
      reject(errorHandler(error));
    }
  });
};
